import './SyncingNotice.css'

const SyncingNotice = (props) => {
    const themeColour = {
        "error": "#c56f6f",
        "loading": "#c5c46d",
        "success": "#73c57c"
    }
    const style = {
        backgroundColor: themeColour[props.status]
    }
    return (
        <span className='SyncingNotice' style={style}>
            {props.children}
        </span>
    )
}

export default SyncingNotice
import { useParams } from 'react-router-dom';
import NavigateButton from '../General/NavigateButton';
import PageSection from '../General/PageSection';
import Participants from './Participants';
import Timeline from './Timeline';
import EventInformaiton from './EventInformation';
import useFetch from '../../hooks/useFetch';
import LoadingMessage from '../General/LoadingMessage/LoadingMessage';

// Organiser Event Manager
function EventManager(props) {
    let params = useParams();
    let eventId = parseInt(params.eventId, 10)
    const { error, isPending, data:eventData } = useFetch('/api/events/' + eventId)
    console.log(eventData)
    return (
        <>
        {error && <p>{error}</p>}
        {isPending && <LoadingMessage />}
        {eventData && 
            <div className="EventManager">
                <div className="topBar">
                    <p>Event ID: {eventData.event_id}</p>
                    <p className="joinCode">Join Code: <span>{eventData.join_code}</span></p>
                </div>
                <EventInformaiton eventId={eventId} />
                <Timeline eventId={eventId} />
                <PageSection>
                    <h2>Step 3. Create the Feedback Form</h2>
                    <p>Click the link below to create the feedback form used by participants to review each others work.</p>
                    <NavigateButton to="feedback-form">Manage Feedback Form</NavigateButton>
                </PageSection>
                <PageSection>
                    <h2>Step 4. Invite Participants</h2>
                    <p>Send this website and the join code to each of the participants. Participants need to be signed up and submit the work before the submission deadline in order to take part.</p>
                    <p className="joinCode">Join Code: <span>{eventData.join_code}</span></p>
                    <h2>Participants signed up:</h2>
                    <Participants />
                </PageSection>
                <PageSection>
                    <h2>Step 5. Start the event.</h2>
                    <p>Please make sure all the participants are signed up and then click 'start event'.</p>
                    <p>Starting the event will cause the following to happen:</p>
                    <ol>
                        <li>No new participants will be able to join the event.</li>
                        <li>Submissions will close.</li>
                        <li>Participants will be assigned peers to review.</li>
                        <li>Reviews will open.</li>
                        <li>Particpants will be able to review their peers.</li>
                    </ol>
                    <NavigateButton to="start-event">Start Event</NavigateButton>
                </PageSection>
                <PageSection>
                    <h2>Step 6. Release feedback.</h2>
                    <p>Feedback will automatically be released to each student when the following conditions are met:</p>
                    <ul>
                        <li>The release feedback deadline is met.</li>
                        <li>The student has completed reviews for all of their assigned peers.</li>
                    </ul>
                    <p>or you can:</p>
                    <ul>
                        <li>Release feedback early, overriding the deadline: <NavigateButton>TODO: Release early</NavigateButton></li>
                        <li>Release feedback for students who have not completed all their reviews: <NavigateButton>TODO: Release for all</NavigateButton></li>
                    </ul>
                </PageSection>
            </div>
        }
        </>
    )

}

export default EventManager
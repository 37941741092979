import './SaveButton.css'
function SaveButton(props) {
    var saveMessage
    if (props.saveMessage) {
        saveMessage = <span>{props.saveMessage}</span>
    }
    return (
        <div className="SaveButton"><button onClick={props.onClick}>Save Changes</button>{saveMessage}</div>
    )
}
export default SaveButton

import {useState, useEffect} from 'react'
import LoadingMessage from '../../General/LoadingMessage/LoadingMessage'
import SubmissionForm from './SubmissionForm'

function Submission(props) {
    const [submission, setSubmission] = useState({})
    const [submissionIsLoaded, setSubmissionIsLoaded] = useState(false)
    const getSubmission = () => {
        setSubmissionIsLoaded(false)
        fetch('/api/events/' + props.eventId + '/submission')
        .then(response => response.json())
        .then(response => {
            if (response.error === "no submission") {
                setSubmission({})
                setSubmissionIsLoaded(true)
            } else if (response.error) {
                alert('error 1: ' + response.error)
                /* TODO: Handle Error */
            } else if (response) {
                setSubmission(response)
                setSubmissionIsLoaded(true)
            }
        })
    }
    const removeSubmission = () => {
        setSubmissionIsLoaded(false)
        fetch('/api/events/' + props.eventId + '/submission', {
            method: 'DELETE',
        })
        .then(response => response.json())
        .then(response => {
            // TODO: handle errors
            getSubmission()
        })
    }
    useEffect(getSubmission, [props.eventId])
    if (submissionIsLoaded) { // if the fetch is complete display data
        if (submission.link) {
            return (
                <div className="">
                    <a className="viewSubmission" href={"http://localhost:8080/public/uploads/"+props.eventId+"/"+submission.link}>View/Download</a>
                    <button className="removeSubmission" onClick={removeSubmission}>Remove</button>
                </div>
            )
        } else {
            return (<SubmissionForm eventId={props.eventId} handleSubmit={getSubmission}></SubmissionForm>)
        }
    } else {
        return(<LoadingMessage></LoadingMessage>) // wait for the fetch to complete
    }
}
export default Submission
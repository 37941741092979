import {useState} from 'react';

function SubmissionForm(props){
	const [selectedFile, setSelectedFile] = useState();
	const [isSelected, setIsSelected] = useState(false);

	const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsSelected(true);
	};

	const handleSubmission = () => {
		const formData = new FormData();

		formData.append('file', selectedFile);

		fetch(
			'/api/events/'+props.eventId+'/submission',
			{
				method: 'POST',
				body: formData,
			}
		)
			.then((response) => response.json())
			.then((result) => {
				props.handleSubmit()
			})
			.catch((error) => {
				console.error('Error:', error);
			});
	};

	return(
   		<span>
			<input type="file" name="file" onChange={changeHandler} />
			{isSelected ?
				(<button onClick={handleSubmission}>Submit</button>) :
				(<span>Select a file to upload</span>)}
		</span>
	)
}

export default SubmissionForm
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import LoadingMessage from "../General/LoadingMessage/LoadingMessage";

const Participants = (props) => {
    let params = useParams();
    let eventId = parseInt(params.eventId, 10)
    const { error, isPending, data:participants, reloadData } = useFetch('/api/events/' + eventId + '/submissions')
    return (
        <>
        <button onClick={reloadData}>Reload</button>
        { error && <div>{ error }</div> }
        { isPending && <LoadingMessage /> }
        { participants && <ParticipantsTable data={participants} eventId={eventId}/> }
        </>
    )
}

const ParticipantsTable = (props) => {
    const participants = props.data
    console.log(participants)
    if (!participants || participants.length < 1) return (<p>No participants have joined the event yet.</p>)
    const rows = []
    for (const i in participants) {
        const participant = participants[i]
        rows.push(<ParticipantRow participant={participant} eventId={props.eventId} key={participant.user_id}/>)
    }
    return (
        <table>
            <tbody>
                 <tr>
                     <th>User ID</th>
                     <th>Name</th>
                     <th>Email</th>
                     <th>Submission ID</th>
                     <th>Submission Link</th>
                 </tr>
                 {rows}
            </tbody>
        </table>
    )
}

const ParticipantRow = (props) => {
    const participant = props.participant
    return (
        <tr>
            <td>{participant.user_id}</td>
            <td>{participant.name}</td>
            <td>{participant.email}</td>
            {(participant.link) ?
                <>
                    <td>{participant.submission_id}</td>
                    <td style={{color:"#73c57c"}}><a className="viewSubmission" href={"http://localhost:8080/public/uploads/"+props.eventId+"/"+participant.link}>View Submission</a></td>
                </>
                :
                <td colSpan={2} style={{color:"#c56f6f"}}>No submission</td>
            }
        </tr>
    )
}

export default Participants
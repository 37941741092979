import './App.css'
import { useEffect, useState } from 'react'
import Login from './components/Login/Login.js'
import Dashboard from './components/Dashboard'

function handleFetchErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText)
  }
  return response
}

const App = () => {

  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  useEffect(() => {
    // call the api to see if the user is logged in
    fetch('/api/auth/me')
    .then(handleFetchErrors)
    .then(response => response.json())
    .then(response => {
      if (response.error) {
        setIsLoggedIn(false)
        if (response.error === "unauthenticated") return setErrorMessage()
        setErrorMessage(response.error)
      } else if (response.name) {
        // user is logged in
        setIsLoggedIn(true)
      }
    }).catch(error => {
      setErrorMessage('There was a problem connecting with the server, please try again.')
      console.log(error)
      setIsLoggedIn(false)
    })
  })

  const loginHandler = (status, message) => {
      if (status === 'success') {
        setIsLoggedIn(true)
        return
      } 
      setIsLoggedIn(false)
      setErrorMessage(message)
  }

  const logoutHandler = () => {
    fetch("/api/auth/logout/", {method: 'delete'})
      .then(response => response.json())
      .then((result) => {
        if (result.success) {
            setIsLoggedIn(false)
            setErrorMessage("You have been logged out successfully")
        } else {
          alert('Error when logging out. Please try again, or clear your browser data.')
        }
      })
  }

  if (!isLoggedIn) return (
    <div className="App">
      <Login loginHandler={loginHandler} loginErrorMessage={errorMessage} />
    </div>
  )

  return (
    <div className="App">
      <Dashboard logoutHandler={logoutHandler} />
    </div>
  )

}

export default App;
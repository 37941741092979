import { useState, useCallback } from "react"

// posts the edited event to the server
const useSave = () => {

    const [saveStatus, setSaveStatus] = useState({status: "success", message: 'No Changes'})

    // used to notify the user that changes have been made but not saved yet
    const declareUnsavedChanges = useCallback(() => {
        setSaveStatus({status: "loading", message: 'Not Saved'})
    }, [])
    // takes some data and sends it to the url
    const saveData = useCallback((url, data) => {
        setSaveStatus({status: "loading", message: 'Syncing'})
        console.log("useSave:", url)
        fetch(url, {
            method: 'PATCH',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: data
        })
        .then(res => {
            if (res.ok) return res.json();
            return res.json().then(res => {
                throw new Error(res.message)
            })
        })
        .then(data => {
            setTimeout(function(){
                setSaveStatus({status: "success", message: 'Changes Saved'})
            },500);
        })
        .catch(err => {
            // auto catches network / connection error
            setSaveStatus({status: "error", message: err.message})
        })

    }, [])
    return {saveStatus, saveData, declareUnsavedChanges}
}

export default useSave
import { Banner } from '../Dashboard'
import NavigateButton from '../General/NavigateButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import './Homepage.css'

const Homepage = () => {
    return (
        <section id="Homepage">
            <Banner title="Welcome" subtitle="Please select from one of the options below" />
            <section>
                <div className="options">
                    <div className="option">
                        <h2>I'm a participant</h2>
                        <p>If you've been invited here to take part in a peer-review event.</p>
                        <NavigateButton to="/participant">Participant Area <FontAwesomeIcon icon={faArrowRight}/></NavigateButton>
                    </div>
                    <div className='divider'><span>OR</span></div>
                    <div className="option">
                        <h2>I'm an organiser</h2>
                        <p>If you're here to organiser a peer-review event, to allow students to exchange feedback on each other's work.</p>
                        <NavigateButton to="/organiser">Organiser Area <FontAwesomeIcon icon={faArrowRight}/></NavigateButton>
                    </div>
                </div>
            </section>
        </section>
    )
}

export default Homepage
import useFetch from "../../hooks/useFetch"
import LoadingMessage from "../General/LoadingMessage/LoadingMessage"
import { useParams } from "react-router-dom";
import NavigateButton from "../General/NavigateButton";

const Peers = (props) => {
    let params = useParams();
    let eventId = parseInt(params.eventId, 10)
    const { error, isPending, data:peers } = useFetch('/api/events/' + eventId + '/peers')
    return (
        <>
        { error && <div>{ error }</div> }
        { isPending && <LoadingMessage /> }
        { peers && <PeerTable data={peers} eventId={eventId}/> }
        </>
    )
}

const PeerTable = (props) => {
    const peers = props.data
    if (!peers || peers.length < 1) return (<p>Peers have not yet been assigned, please check back once the submission deadline is complete.</p>)
    const rows = []
    for (const i in peers) {
        const peer = peers[i]
        rows.push(<PeerRow peer={peer} eventId={props.eventId}/>)
    }
    return (
        <table>
            <tbody>
                 <tr>
                     <th>Submission ID</th>
                     <th>Submission</th>
                     <th>Review Form</th>
                 </tr>
                 {rows}
            </tbody>
        </table>
    )
}

const PeerRow = (props) => {
    const isComplete = false
    return (
        <tr>
            <td>{props.peer.submission_id}</td>
            <td><a target="_blank" href={"http://localhost:8080/public/uploads/" + props.eventId + "/" + props.peer.link}>Download</a></td>
            <td>
                <NavigateButton to={"/participant/event/" + props.eventId + "/submission/" + props.peer.submission_id + "/review"}>Review Form</NavigateButton>
                {isComplete ? <span style={{color:"#73c57c"}}> Complete</span>:<span style={{color:"#c56f6f"}}> Not complete</span>}
            </td>
        </tr>
    )

}

export default Peers
import { useParams, useNavigate } from 'react-router-dom';
import JoinEventForm from './JoinEventForm';
import useFetch from '../../hooks/useFetch';
import LoadingMessage from '../General/LoadingMessage/LoadingMessage';

function ParticipantAside(props) {
    const navigate = useNavigate();
    let params = useParams();
    let selectedEvent = parseInt(params.eventId, 10)
    const { error, isPending, data:eventList, reloadData:updateEventList } = useFetch('/api/events/joined')
    const handleJoinEvent = () => {
        updateEventList()
    }
    const selectEvent = (eventId) => {
        navigate("/participant/event/" + eventId)
    }
    if (isPending) return <LoadingMessage></LoadingMessage>
    if (error) return <p>{error}</p>
    var eventItems = []
    if (eventList !== undefined && eventList.length) {
        for (var eventItem in eventList) {
            const eventId = eventList[eventItem].event_id
            const isSelected = (selectedEvent === eventId)
            eventItems.push(
            <JoinedEventListItem 
                key={eventId} 
                eventItem={eventList[eventItem]} 
                selectEvent={selectEvent}
                isSelected={isSelected}
            />)
        }
    } else {
        eventItems.push(<p key="no-events" className="no-events">You haven't joined any events.</p>)
    }
    return (
        <>
            <JoinEventForm handleJoinEvent={handleJoinEvent}/>
            <div className="event-list">
                {eventItems}
            </div>
        </>
    )
}

function JoinedEventListItem(props) {
    var isSelected = props.isSelected
    return (
        <div 
            className={`EventListItem ${isSelected ? "selected" : ""}`}
            onClick={() => props.selectEvent(props.eventItem.event_id)}
            key={props.eventItem.event_id}
        >
            <p>{props.eventItem.title}</p>
        </div>
    )
}
export default ParticipantAside
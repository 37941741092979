import roadmap from './roadmap.png'
function ParticipantHome() {
    return (
        <div>
            <h1>Welcome</h1>
            <p>Please select an event from the menu to start.</p>
            <h2>Getting Started</h2>
            <p>To join an event, please enter the join code provided by the organiser.</p>
            <p>Alternatively, open the link provided by the organiser and log in.</p>
            <p>Once you have joined an event it will show in the side menu, select it to get started.</p>
            <h2>How it works.</h2>
            <img width="50%" src={roadmap} alt="Logo" />
        </div>
    )
}
export default ParticipantHome
import {Banner, SidebarLayout} from '../Dashboard'
import ParticipantAside from './ParticipantAside'
import { Outlet } from 'react-router-dom'

 function Participant(props) {
    return (
        <section id="participant">
            <Banner title="Participant Area" subtitle="This area is for taking part in feedback events."></Banner>
            <SidebarLayout aside={<ParticipantAside />}>
                <Outlet/>
            </SidebarLayout>
        </section>
    );
}

export default Participant
import { useState, useEffect } from "react";
const CountDown = (props) => {
    const [countdown, setCountdown] = useState();

    useEffect(() => {
        const countdownDate = new Date(props.date)
        const interval = setInterval(() => {
            // Get today's date and time
            var now = new Date().getTime();
            // Find the distance between now and the count down date
            var distance = countdownDate - now;
            // return text and clear interval if no time left
            if (distance < 0 || !props.date) {
                setCountdown("Time's up!")
                return clearInterval(interval)
            }
            var timeText = "";
            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24))
            if (days > 0) timeText += days + " days, "
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
            if (hours > 0) timeText += hours + " hours, "
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
            if (minutes > 0) timeText += minutes + " minutes, "
            var seconds = Math.floor((distance % (1000 * 60)) / 1000)
            timeText += seconds + " seconds."
            setCountdown(timeText)

        }, 1000)
        return () => clearInterval(interval)
      }, [props.date]);

      return (
          <span>{countdown}</span>
      )
}
export default CountDown
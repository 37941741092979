import { Outlet } from 'react-router-dom';
import {Banner, SidebarLayout} from '../Dashboard';
import OrganiserAside from './OrganiserAside';

function Organiser() {
    return (
        <section id="organiser">
            <Banner title="Organiser Area" subtitle="This area is for managing feedback events."></Banner>
            <SidebarLayout aside={<OrganiserAside />}>
                <Outlet/>
            </SidebarLayout>
        </section>
    );
}

export default Organiser
function OrganiserHome() {
    return (
        <div>
            <h1>Welcome</h1>
            <p>Please select an event from the menu to manage it.</p>
            <h2>Getting Started</h2>
            <p>Click 'Create Event' to create a new feedback event.</p>
        </div>
    )
}
export default OrganiserHome
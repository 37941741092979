import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import LoadingMessage from '../General/LoadingMessage/LoadingMessage'
import useFetch from '../../hooks/useFetch'
import { v4 as uuidv4 } from 'uuid'
import SaveButton from '../General/SaveButton'
// form management
const FeedbackFormManager = (props) => {
    let params = useParams()
    let eventId = parseInt(params.eventId, 10)
    const { error, isPending, data:feedbackFormData } = useFetch('/api/events/' + eventId + '/feedback-form')
    return (
        <>
        { error && <div>{error}</div> }
        { isPending && <LoadingMessage /> }
        { feedbackFormData && <FeedbackForm eventId={eventId} data={feedbackFormData} /> }
        </>
    )
}

const FeedbackForm = (props) => {
    const eventId = props.eventId
    const [feedbackForm, setFeedbackForm] = useState(props.data)
    const [saveMessage, setSaveMessage] = useState("")


    const save = () => {
        fetch('/api/events/' + eventId + '/feedback-form', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                feedbackForm
            )
        })
        .then(res => {
            if (!res.ok) {
              throw Error('could not fetch the data for that resource');
            } 
            return res.json();
          })
        .then(data => {
            setSaveMessage('Saved Successfully')
        })
        .catch(err => {
            // auto catches network / connection error
            setSaveMessage(err.message);
        })
        // only display the message for 5 seconds
        setTimeout(function(){
            setSaveMessage('')
        },5000);
    }

    const handleNewItem = () => {
        const defaultItem = {title: "New Question", description: "", type: "shorttext"}
        setFeedbackForm([...feedbackForm, defaultItem])
    }
    const handleDelete = (index) => {
        const feedbackFormCopy = [...feedbackForm]
        feedbackFormCopy.splice(index, 1)
        setFeedbackForm(feedbackFormCopy)
    }
    const handleChange = (index, type, title, description) => {
        const newFeedbackForm = feedbackForm
        newFeedbackForm[index].type = type
        newFeedbackForm[index].title = title
        newFeedbackForm[index].description = description
        setFeedbackForm(newFeedbackForm)
    }
    const handleMoveUp = (index) => {
        if (index < 1) return
        const feedbackFormCopy = [...feedbackForm]
        const item = feedbackFormCopy[index];
        feedbackFormCopy[index] = feedbackFormCopy[index-1];
        feedbackFormCopy[index-1] = item;
        setFeedbackForm(feedbackFormCopy)
    }
    const handleMoveDown = (index) => {
        if (index > feedbackForm.length - 2) return
        handleMoveUp(index + 1)
    }

    var formItems = []
    for (let i in feedbackForm) {
        const formItem = feedbackForm[i]
        formItems.push(<FeedbackItem
            key={uuidv4()} index={i}
            formItem={formItem}
            handleChange={handleChange}
            handleDelete={handleDelete}
            handleMoveUp={handleMoveUp}
            handleMoveDown={handleMoveDown}
            isFirst={(parseInt(i) === 0)}
            isLast={(parseInt(i) === (feedbackForm.length - 1))}
            />)
    }
    if (formItems.length < 1) formItems = [<p key="no-items">You have not added any items to this form.</p>]

    return (
        <div className="FeedbackFormManager">
            <SaveButton onClick={save} saveMessage={saveMessage} />
            <Link to={"/organiser/event/" + eventId}>Back to Event</Link>
            <div className="feedbackFormContainer">
                {formItems}
            </div>
            <div className="addItem">
                <button onClick={handleNewItem}>Add Another Question</button>
            </div>
       </div>
    );
}

const FeedbackItem = (props) => {
    const formItem = props.formItem
    const [itemType, setItemType] = useState(formItem.type)
    const [title, setTitle] = useState(formItem.title)
    const [description, setDescription] = useState(formItem.description)
    const index = parseInt(props.index)

    const handleTitleChange = (e) => {
        const newTitle = e.target.value
        setTitle(newTitle)
        props.handleChange(index, itemType, newTitle, description)
    }
    const handleDescriptionChange = (e) => {
        const newDescription = e.target.value
        setDescription(newDescription)
        props.handleChange(index, itemType, title, newDescription)
    }

    const handleTypeChange = (e) => {
        const newType = e.target.value
        setItemType(newType)
        props.handleChange(index, newType, title, description)
    }

    return (
        <div className="FeedbackItem">
            <h4>Question {index+1}:</h4>
            <p>Title</p>
            <input type="text" value={title} onChange={handleTitleChange}/>
            <p>Description</p>
            <input type="text" value={description} onChange={handleDescriptionChange}/>
            <p>Answer Type</p>
            <div onChange={handleTypeChange} defaultValue={itemType}>
                <label>Short Text</label>
                <input type="radio" value="shorttext" defaultChecked={itemType === "shorttext"}/>
                <label>Long Text</label>
                <input type="radio" value="longtext" defaultChecked={itemType === "longtext"}/>
                <label>Number</label>
                <input type="radio" value="number" defaultChecked={itemType === "number"}/>
                <label>Yes or No</label>
                <input type="radio" value="yesno" defaultChecked={itemType === "yesno"}/>
            </div>
            <button onClick={() => props.handleDelete(index)}>Remove Question</button>
            {!props.isFirst && <button onClick={() => props.handleMoveUp(index)}>Move Up</button> }
            {!props.isLast && <button onClick={() => props.handleMoveDown(index)}>Move Down</button>}
        </div>
    )

}
export default FeedbackFormManager
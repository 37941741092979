import GoogleLogin from 'react-google-login'
import logo from '../../images/logo.png'
import './Login.css'

const Login = (props) => {
  const { loginHandler, loginErrorMessage } = props
  
  const responseGoogle = async (response) => {
    if (response.error) {
      console.log("User unable to log in: " + response.error)
      loginHandler('error', response.error)
      return;
    }
    const res = await fetch("/api/auth/google", {
      method: "POST",
      body: JSON.stringify({
        token: response.tokenId
      }),
      headers: {
        "Content-Type": "application/json"
      }
    })
    const data = await res.json()
    loginHandler('success', data)
  }

  return (
    <div id="LoginBox">
      <img className="logo" src={logo} alt="logo" />
      <h1 className="title">Peer Review System</h1>
      <p className="alert">{loginErrorMessage}</p>
      <div id="GoogleLogin">
        <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        buttonText="Log in with Google"
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={'single_host_origin'}
        />
      </div>
    </div>
  )

}

export default Login;

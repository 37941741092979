import {useRef} from 'react'
import { useNavigate } from 'react-router-dom';

function JoinEventForm(props) {
    
    const navigate = useNavigate()
    const inputRef = useRef(null);

    const handleSubmit = (e) => {
         e.preventDefault()
         const joinCode = e.target.joinCode.value
         if (!joinCode) {
             alert('please enter a join code')
             // TODO: hint to enter input
             return
         }
         navigate('/participant/join/' + joinCode)
         inputRef.current.value = ""
         props.handleJoinEvent()
         
    }
  
    return (
      <form className="JoinEventForm" onSubmit={e => {handleSubmit(e)}}>
        <input 
          name='joinCode' 
          type='text' 
          placeholder='XXXXXX'
          maxLength="6"
          ref={inputRef}
        />
        <br/>
        <input 
          className='submitButton'
          type='submit'
          value='Join Event' 
        />
        <br/>
        <label>To join a feedback event, enter the event code provided by the host.</label>
      </form>
    )
}
export default JoinEventForm
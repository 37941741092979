import { useState, useEffect, useCallback } from 'react';

const useFetch = (url) => {
  const [state, setState] = useState({ data: null, isPending: true, error: null })
  // reloads the data
  const reloadData = () => {
    // keep old data until new data has arrived
    setState({ data: state.data, isPending: true, error: null })
    loadData()
  }

  const loadData = useCallback(() => {
    //setTimeout(() => {
      fetch(url)
      .then(res => {
        console.log("useFetch(): ", url)
        if (res.ok) return res.json()
        return res.json().then(res => {
          throw new Error(res.message)
        })
      })
      .then(data => {
        setState({ data: data, isPending: false, error: null })
      })
      .catch(err => {
        // catch the error, and display the message to the user
        setState({ data: null, isPending: false, error: err.message })
      })
    //}, 500); // simulating a slow API -- remove in production!
  }, [url])

  useEffect(() => {
    // reset values so components re-render properly
    setState({ data: null, isPending: true, error: null })
    loadData()
  }, [url, loadData])

  return { ...state, reloadData }
}
 
export default useFetch
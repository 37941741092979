import { useNavigate } from 'react-router-dom'
import './NavigateButton.css'
function NavigateButton(props) {
    const to = props.to
    const navigate = useNavigate()
    const navigateTo = () => {
        if (to) navigate(to)
    }
    return (
        <button className="NavigateButton" onClick={navigateTo}>{props.children}</button>
    )
}
export default NavigateButton

import {useEffect, useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom';


function JoinEvent(props) {
    const navigate = useNavigate()
    let params = useParams();
    let joinCode = params.joinCode
    const [message, setMessage] = useState();

    useEffect(() => {
        if (!joinCode) {
            setMessage('No join code provided.')
            return
            // TODO: handle
        }
        fetch('/api/events/join/' + joinCode)
            .then(response => response.json())
            .then(data => {
                // TODO: handle errors
                if (data.success) {
                    navigate("/participant/event/" + data.event_id)
                }
            })
    }, [joinCode, navigate])
  
    return (
      <p>{message}</p>
    )
}
export default JoinEvent
import './Dashboard.css';
import logo from '../../images/logo.png';
import Participant from '../Participant';
import Organiser from '../Organiser'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
  } from "react-router-dom";
import EventView from '../Participant/EventView';
import EventManager from '../Organiser/EventManager';
import FeedbackFormManager from '../Organiser/FeedbackFormManager';
import JoinEvent from '../Participant/JoinEvent';
import ParticipantHome from '../Participant/ParticipantHome';
import OrganiserHome from '../Organiser/OrganiserHome';
import Peers from '../Participant/Peers'
import Homepage from '../Homepage';
import StartEvent from '../Organiser/StartEvent';
import Review from '../Participant/Review';
 
/**
 * Dashboard
 */

function Dashboard(props) {
    return (
      <Router>
        <Header logoutHandler={props.logoutHandler}/>
        <Routes>
            <Route path='*' element={<Error404 />} />
            <Route path="/" element={<Homepage />} />
            <Route path="/participant" element={<Participant />}>
                <Route index element={<ParticipantHome />}/>
                <Route path="event/:eventId" element={<EventView />} />
                <Route path="event/:eventId/peers" element={<Peers />} />
                <Route path="join/:joinCode" element={<JoinEvent />} />
                <Route path="event/:eventId/submission/:submissionId/review" element={<Review />} />
            </Route>
            <Route path="/organiser" element={<Organiser />}>
                <Route index element={<OrganiserHome />}/>
                <Route path="event/:eventId" element={<EventManager />} />
                <Route path="event/:eventId/feedback-form" element={<FeedbackFormManager />} />
                <Route path="event/:eventId/start-event" element={<StartEvent />} />
            </Route>
        </Routes>
        <Footer/>
    </Router>
    )
}


/**
 * Components for the main appication structure.
 */

function Header(props) {
    return (
        <header>
            <ul>
                <li className='logo'>
                    <Link to="/"><img id="main-logo" src={logo} alt="logo" /></Link>
                </li>
                <li><Link to="/participant">Participant</Link></li>
                <li><Link to="/organiser">Organiser</Link></li>
                <li className="logout-button-container">
                    <button className="logout-button" onClick={() => props.logoutHandler()}>Logout</button>
                </li>
            </ul>
        </header>
    );
}

function SidebarLayout(props) {
    return (
        <section className="SidebarLayout">
            <aside>{props.aside}</aside>
            <main>{props.children}</main>
        </section>
    );
}

function Banner(props) {
    return (<div className="Banner"><h2>{props.title}</h2><p>{props.subtitle}</p></div>)
}

function Error404() {
    return (<Banner title="Error 404" subtitle="The requested page could not be found."></Banner>)
}

function Footer(props) {
    return (<footer>Peer Review System - UP904254</footer>)
}

export default Dashboard
export {
    SidebarLayout,
    Banner,
}
import React from 'react'
import LoadingMessage from '../General/LoadingMessage/LoadingMessage'
import Submission from './Submission'
import { useParams } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import CountDown from '../General/CountDown';
import PageSection from '../General/PageSection';
import Peers from './Peers';

function EventView(props) {
    let params = useParams();
    let eventId = parseInt(params.eventId, 10)
    const { error, isPending, data:eventData } = useFetch('/api/events/' + eventId)

    return (
        <>
        { error && <div>{ error }</div> }
        { isPending && <LoadingMessage /> }
        { eventData && <Event data={eventData} /> }
        </>
    )
}

const Event = (props) => {
    const {
        title,
        description,
        event_id:eventId,
        submission_deadline:submissionDeadline,
        allow_submissions:allowSubmissions,
        feedback_deadline:feedbackDeadline,
        allow_feedback:allowFeedback,
        release_feedback_deadline:releaseFeedbackDeadline,
        release_feedback:releaseFeedback
    } = props.data
    const dateToString = (date) => {
        const d = new Date(date);
        return d.toString();
    }
    return (
        <div className="participantEventView">
            <PageSection>
                <h1>{title}</h1>
                <p>{description}</p>
            </PageSection>
            <PageSection>
                <h2>Step 1. Submit your work</h2>
                <p>Ensure your work is uploaded before the deadline, or you will not be able to take part in the event.</p>
                <table>
                    <tbody>
                        <tr>
                            <th>Deadline:</th>
                            <td>{dateToString(submissionDeadline)}</td>
                        </tr>
                        <tr>
                            <th>Time Left:</th>
                            <td><CountDown date={submissionDeadline} /></td>
                        </tr>
                        <tr>
                            <th>Status:</th>
                            <td>{ allowSubmissions ? "Submissions are open." : "Submissions are now closed."}</td>
                        </tr>
                    </tbody>
                </table>
                <h2>Your Submission</h2>
                <Submission eventId={eventId} />
            </PageSection>
            <PageSection>
                <h2>Step 2. Give feedback to peers</h2>
                <p>Once submissions are closed, you will be assigned peers. You will then have until the deadline below to complete your reviews.</p>
                <table>
                    <tbody>
                        <tr>
                            <th>Deadline:</th>
                            <td>{dateToString(feedbackDeadline)}</td>
                        </tr>
                        <tr>
                            <th>Time Left:</th>
                            <td><CountDown date={feedbackDeadline} /></td>
                        </tr>
                        <tr>
                            <th>Status:</th>
                            <td>{ allowFeedback ? "Feedback is open. Please complete your reviews before the deadline is complete." : "Feedback is closed."}</td>
                        </tr>
                    </tbody>
                </table>
                <h2>Your Peers</h2>
                <Peers />
            </PageSection>
            <PageSection>
                <h2>Step 3. Receive your feedback</h2>
                <p>Your feedback will be released when both of the following conditions are met:</p>
                <ul>
                    <li>The release deadline has been met.</li>
                    <li>You have completed all of your reviews.</li>
                </ul>
                <table>
                    <tbody>
                        <tr>
                            <th>Deadline:</th>
                            <td>{dateToString(releaseFeedbackDeadline)}</td>
                        </tr>
                        <tr>
                            <th>Time Left:</th>
                            <td><CountDown date={releaseFeedbackDeadline}/></td>
                        </tr>
                        <tr>
                            <th>Status:</th>
                            <td>{ releaseFeedback ? "Feedback has been released." : "Feedback has not yet been released."}</td>
                        </tr>
                    </tbody>
                </table>
                <h2>Your feedback</h2>
                <p>Your feedback has not yet been released.</p>
            </PageSection>
        </div>
    )
}

export default EventView
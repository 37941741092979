import useFetch from "../../../hooks/useFetch";
import { useState, useEffect } from "react";
import PageSection from "../../General/PageSection";
import CountDown from "../../General/CountDown";
import LoadingMessage from "../../General/LoadingMessage/LoadingMessage";
import SyncingNotice from "../../General/SyncingNotice";
import useSave from "../../../hooks/useSave";
import './Timeline.css'

// Timeline
function Timeline(props) {
    let eventId = props.eventId;
    // load the event info
    const { error, isPending, data:eventData } = useFetch('/api/events/' + eventId)
    const [state, setState] = useState({
        submitDateTime: '',
        reviewDateTime: '',
        releaseDateTime: ''
    })
    const {saveStatus, saveData} = useSave()

    // load data into state
    useEffect(() => {
        if (eventData) {
            setState({
                submitDateTime: (convertDateTimeLocal(eventData.submission_deadline) || ''),
                reviewDateTime: (convertDateTimeLocal(eventData.feedback_deadline) || ''),
                releaseDateTime: (convertDateTimeLocal(eventData.release_feedback_deadline) || '')
            })
        }
    }, [eventData])

    // change from server time to client time
    const convertDateTimeLocal = (dateTime) => {
        if (!dateTime) return undefined
        var date = new Date(dateTime);
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date.toISOString().slice(0,16);
    }
    // change from client time to server time
    const convertDateTimeServer = (dateTime) => {
        if (!dateTime) return undefined
        var date = new Date(dateTime);
        date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
        return date.toISOString().slice(0,16);
    }

    const handleChange = (e) => {
            const name = e.target.name
            const value = e.target.value
            const newState = {...state}
            newState[name] = value
            setState(newState)
            saveData(('/api/events/' + eventId), JSON.stringify({
                submission_deadline: convertDateTimeServer(newState.submitDateTime),
                feedback_deadline: convertDateTimeServer(newState.reviewDateTime),
                release_feedback_deadline: convertDateTimeServer(newState.releaseDateTime)
            })) // post all changes to the server
    }

    return (
        <div id="Timeline">
            {error && <p>error</p>}
            {isPending && <LoadingMessage />}
            {eventData && 
                    <PageSection>
                        <h2>Step 2. Event Timeline </h2><SyncingNotice status={saveStatus.status}>{saveStatus.message}</SyncingNotice>
                        <p>Let the participants know what they need to do and when...</p>
                        <div class="timeline">
                            <div class="timeline-item right">
                                <div class="content">
                                    <h3>Submission Deadline</h3>
                                    <p>This is when participants must have joined the event and submitted their work.</p>
                                    <label htmlFor="submitDateTime">Submission Deadline</label>
                                    <input
                                            type="datetime-local"
                                            name="submitDateTime"
                                            value={state.submitDateTime}
                                            onChange={handleChange}
                                        ></input>
                                    <span>Time Left: <CountDown date={state.submitDateTime}/></span>
                                </div>
                            </div>
                            <div class="timeline-item right">
                                <div class="content">
                                    <h3>Review Deadline</h3>
                                    <p>This is when participants need to complete their assigned reviews.</p>
                                    <label htmlFor="reviewDateTime">Complete Reviews Deadline: </label>
                                    <input
                                            type="datetime-local"
                                            name="reviewDateTime"
                                            value={state.reviewDateTime}
                                            onChange={handleChange}
                                        ></input>
                                    <span>Time Left: <CountDown date={state.reviewDateTime}/></span>
                                </div>
                            </div>
                            <div class="timeline-item right">
                                    <div class="content">
                                    <h3>Release Deadline: </h3>
                                    <p>When participants can access the feedback they've received.</p>
                                    <label htmlFor="reviewDateTime">Release Feedback: </label>
                                    <input
                                            type="datetime-local"
                                            name="releaseDateTime"
                                            value={state.releaseDateTime}
                                            onChange={handleChange}
                                        ></input>
                                    <span>Time Left: <CountDown date={state.releaseDateTime}/></span>
                                </div>
                            </div>
                        </div>
                    </PageSection>
            }
        </div>
    )

}

export default Timeline
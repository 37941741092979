import { useParams, useNavigate } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import LoadingMessage from '../General/LoadingMessage/LoadingMessage';

// Organiser Aside Modules
function OrganiserAside(props) {
    const navigate = useNavigate()
    const { error, isPending, data:eventList, reloadData:updateEventList } = useFetch('/api/events/owned')
    const handleCreateEvent = () => {
        fetch('/api/events', {method: "POST"})
        .then(response => response.json())
        .then(data => {
            // TODO: handle error
            // if created successfully update the event list to get info
            updateEventList()
            // redirect to the new event
            navigate("/organiser/event/" + data.event_id)
        });
    }
    const selectEvent = (eventId) => {
        navigate("/organiser/event/" + eventId)
    }
    if (isPending) return <LoadingMessage></LoadingMessage>
    if (error) return <p>{error}</p>
    var eventItems = []
    if (eventList !== undefined && eventList.length) {
        for (var eventItem in eventList) {
            eventItems.push(<EventListItem key={eventList[eventItem].event_id} eventItem={eventList[eventItem]} selectEvent={selectEvent} />)
        }
    } else {
        eventItems.push(<p key="no-events" className="no-events">You haven't created any events.</p>)
    }
    return (
        <>
            <CreateEventButton handleCreateEvent={handleCreateEvent}/>
            <div className="event-list">
                {eventItems}
            </div>
        </>
    );
}
function EventListItem(props) {
    let params = useParams();
    let eventId = parseInt(params.eventId, 10)
    var selected = (props.eventItem.event_id === eventId) ? "selected" : "";
    return (
        <div 
            className={"EventListItem " + selected}
            onClick={() => props.selectEvent(props.eventItem.event_id)}
            key={props.eventItem.event_id}
        >
            <p>{props.eventItem.title}</p>
        </div>
    )
}
function CreateEventButton(props) {
    return (<button className="CreateEventButton" onClick={() => props.handleCreateEvent()}>Create Event</button>)
}

export default OrganiserAside
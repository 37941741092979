import useFetch from "../../hooks/useFetch"
import LoadingMessage from "../General/LoadingMessage/LoadingMessage"
import { useParams } from "react-router-dom"
import PageSection from '../General/PageSection'
import NavigateButton from '../General/NavigateButton'
import { useState } from "react"
const StartEvent = (props) => {
    let params = useParams();
    let eventId = parseInt(params.eventId, 10)
    const { error:error1, isPending:isPending1, data:participants } = useFetch('/api/events/' + eventId + '/submissions')
    const {error:error2, isPending:isPending2, data:event } = useFetch('/api/events/' + eventId)
    // combine responses
    const isPending = (isPending1 || isPending2)
    var error = undefined
    if (error1) error+=" - Unable to fetch submissions: " + error1
    if (error2) error+=" - Unable to fetch event: " + error2

    const [numberOfPeers, setNumberOfPeers] = useState(1)

    // TODO: check if event has already started
    if (isPending) return <LoadingMessage/>
    if (error) return <p>{error}</p>
    if (event.peers_are_assigned) return <p>Peers have already been assigned for this event.</p>

    // check the event is ready to be started
    // these checks are also run on the server
    const errors = []
    const warnings = []
    if (participants) {
        if (participants.length <= 1) errors.push(<li>Error: You need at least two participants to start the event.</li>)
        var nonSubmitCount = 0
        for (var i in participants) {
            if (participants[i]['link'] == null) nonSubmitCount++
        }
        if (nonSubmitCount === 1) warnings.push(<li>Warning: There is 1 participant who has not submitted their work. They will be exclused from the event if you continue.</li>)
        if (nonSubmitCount > 1) warnings.push(<li>Warning: There are {nonSubmitCount} participants who have not submitted their work. They will be exclused from the event if you continue.</li>)
        if (nonSubmitCount > participants.length - 2) errors.push(<li>Error: At least two participants need to have submitted their work.</li>)
    }

    const handleChange = (e) => {
        if (e.target.value > participants.length-1) {
            return alert("You cannot have more than " + (participants.length-1) + " peers.")
        }
        if (e.target.value < 1) {
            return alert("You must have at least one peer.")
        }
        setNumberOfPeers(e.target.value)
    }

    const startEvent = () => {
        // TODO

        fetch('/api/events/' + eventId + '/peers/assign',
        {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify({numberOfPeers: numberOfPeers})
        })
        .then(res => {
            if (res.ok) return res.json()
            return res.json().then(res => {
            throw new Error(res.message)
            })
        })
        .then(data => {
            alert("It worked" + data.message)
        })
        .catch(err => {
            // catch the error, and display the message to the user
            alert("It broke" + err.message)
        })
        // 2. submit start event request to server

        // 3. if errors: display errors

        // 4. if success: show success, redirect back to event page


    }

    return (
        <PageSection>
            {error && <p>{error}</p>}
            {isPending && <LoadingMessage />}
            {participants && 
                <>
                <NavigateButton to={"/organiser/event/" + eventId}>Back to event</NavigateButton>
                <h2>Before you continue...</h2>
                {errors.length > 0 && <p>Please action the following errors before you can start the event:</p> }
                <ul>
                    {errors}
                </ul>
                {warnings.length > 0 && <p>Please review the following warnings:</p> }
                <ul>
                    {warnings}
                </ul>
                {errors.length > 0 ?
                    <p>You must fix any errors before continuing.</p>
                    :
                    <>
                        <h2>How many peers would you like each participant to be assigned?</h2>
                        <p>As you have {participants.length} participants you can assign up to {participants.length-1} peers.</p>
                        <label htmlFor="numberOfPeers">Number of peers (min 1 - max {participants.length-1}):</label>
                        <input type="number" id="numberOfPeers" name="numberOfPeers" min="1" max={participants.length-1} value={numberOfPeers} onChange={handleChange}/>
                        <button onClick={startEvent}>Start Event</button><span>This cannot be undone</span>
                    </>
                }
                </>
            }
        </PageSection>
    )
}

export default StartEvent
import useFetch from "../../hooks/useFetch";
import useSave from "../../hooks/useSave";
import { useState, useEffect } from "react";
import LoadingMessage from "../General/LoadingMessage/LoadingMessage";
import SyncingNotice from "../General/SyncingNotice";
import PageSection from "../General/PageSection";

const EventInformaiton = (props) => {

    let eventId = props.eventId;
    // load the event info
    const { error, isPending, data:eventData } = useFetch('/api/events/' + eventId)
    const [state, setState] = useState({
        title: '',
        description: '',
    })
    const {saveStatus, saveData, declareUnsavedChanges} = useSave()

    // save when user stops making changes (1s)
    useEffect(() => {
        const timeOutId = setTimeout(() => {
            saveData(('/api/events/' + eventId), JSON.stringify({
                title: state.title,
                description: state.description
            }))
        }, 1000) // post all changes to the server, 500);
        return () => clearTimeout(timeOutId);
    }, [state, eventId, saveData]);

    // load data into state
    useEffect(() => {
        if (eventData) {
            setState({
                title: eventData.title,
                description: eventData.description,
            })
        }
    }, [eventData])

    const handleChange = (e) => {
            declareUnsavedChanges()
            const name = e.target.name
            const value = e.target.value
            const newState = {...state}
            newState[name] = value
            setState(newState)
    }

    return (
        <PageSection>
            <h2>Step 1. Event Information</h2> 
                <p>Let the participants know what they're doing...</p>
            {error && <p>{error}</p>}
            {isPending && <LoadingMessage />}
            {eventData &&
                <>
                <SyncingNotice status={saveStatus.status}>{saveStatus.message}</SyncingNotice>
                <label>Event Title</label>
                <input type="text" name="title" value={state.title} onChange={handleChange}/>

                <label>Event Description</label>
                <input type="text" name="description" value={state.description} onChange={handleChange}/>
                </>
            }
        </PageSection>
    )

}

export default EventInformaiton

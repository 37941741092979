import './LoadingMessage.css'

const LoadingMessage = (props) => {
    const { message = "" } = props // a default message can be put here
    return (
        <div className="LoadingMessage">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div><br/>
            <span>{message}</span>
        </div>
    )
}

export default LoadingMessage;